import React, {
  useState,
  useEffect
} from 'react';
import type { FC } from 'react';
import Cookies from 'js-cookie';
import {
  Box,
  Button,
  Link,
  Portal,
  Typography,
  makeStyles,
Grid
} from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: 'none',
    zIndex: 2000
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  }
}));

const CookiesNotification: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    Cookies.set('consent', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const consent: string | undefined = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography
          variant="h4"
          color="inherit"
        >
          To use this site, and download and use Conduct you will need to consent to:
        </Typography>
        <Box mt={2} />
        <Typography
          variant="h5"
          color="inherit"
        >
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <Link
              component="a"
              color="inherit"
              underline="always"
              href="https://www.iubenda.com/privacy-policy/7970019/cookie-policy"
              target="_blank"
            >
              Cookie Policy
            </Link>
          </Grid>
          <Grid item>
            <Link
              component="a"
              color="inherit"
              underline="always"
              href="https://www.iubenda.com/privacy-policy/7970019"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </Grid>
          <Grid item>
            <Link
              component="a"
              color="inherit"
              underline="always"
              href="https://www.iubenda.com/terms-and-conditions/7970019"
              target="_blank"
            >
              Terms & Conditions
            </Link>
          </Grid>
        </Grid>
        </Typography>
        <Box
          mt={3}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            fullWidth
            onClick={handleClose}
            variant="contained"
            className={classes.action}
          >
            I Agree
          </Button>
        </Box>
      </div>
    </Portal>
  );
};

export default CookiesNotification;
