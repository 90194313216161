import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: 'us-east-1_3yH0taSOa',
  ClientId: '24qr8secfgve7fgb65oe9i5rg4'
};
const userPool = new CognitoUserPool(poolData);

interface AuthenticationState {
  cognitoUser: CognitoUser;
  cognitoSession: CognitoUserSession;
  userAttributes: any;
  requiredAttributes: any;
  newPasswordRequired: boolean;
};

const initialState: AuthenticationState = {
  cognitoUser: null,
  cognitoSession: null,
  userAttributes: null,
  requiredAttributes: null,
  newPasswordRequired: false
};

const slice = createSlice({
  name: 'cognito',
  initialState,
  reducers: {
    setUser(state: AuthenticationState, action: PayloadAction<{ cognitoUser: CognitoUser }>) {
      const { cognitoUser } = action.payload;
      state.cognitoUser = cognitoUser;
      state.newPasswordRequired = false;
    },
    setSession(state: AuthenticationState, action: PayloadAction<{ session: CognitoUserSession }>) {
      const { session } = action.payload;
      state.cognitoSession = session;
      state.newPasswordRequired = false;
    },
    setAttributes(state: AuthenticationState, action: PayloadAction<{ userAttributes: any, requiredAttributes: any }>) {
      const { userAttributes, requiredAttributes } = action.payload;
      state.userAttributes = userAttributes;
      state.requiredAttributes = requiredAttributes;
      state.newPasswordRequired = true;
    },
  }
});

export const reducer = slice.reducer;

export const login = ({ username, password }): AppThunk => async (dispatch) => {
  const auth = new AuthenticationDetails({
    Username: username,
    Password: password
  });
  const userData = { Username: username, Pool: userPool };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.authenticateUser(auth, {
    onSuccess: session => slice.actions.setSession({ session }),
    onFailure: err => slice.actions.setSession({ session: null }),
    newPasswordRequired: (userAttributes, requiredAttributes) => {
      delete userAttributes.email_verified;
      slice.actions.setAttributes({ userAttributes, requiredAttributes })
    }
  });
  slice.actions.setUser({ cognitoUser });
}

export const changePassword = (newPassword : string): AppThunk => async (dispatch, getState) => {
  const { cognito: { cognitoUser, userAttributes } } = getState();
  cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
    onSuccess: session => slice.actions.setSession({ session }),
    onFailure: err => slice.actions.setSession({ session: null })
  });
}

export const logout = (): AppThunk => async (dispatch, getState) => {
  const { cognito: { cognitoUser } } = getState();
  if (cognitoUser) {
    cognitoUser.signOut()
  }
}

export default slice;
