import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';

import Feature from './Feature';

interface FeaturesProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  }
}));

const Features: FC<FeaturesProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          Explore Conduct
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          What we offer
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Feature
              enumerator="01"
              heading="Unmatched Download Speed"
              text="Conduct uses Amazon's industry-leading CDN, to deliver your files faster and with more reliability.
                    Using 217 separate edge locations so no matter where you live in the world, you get your product as quick as possible."
            />
           <Feature
              enumerator="02"
              heading="No Login or Passwords"
              text="Conduct will remember what you've installed on your machine without you needing to login.
                    Giving you peace of mind and taking away the pain of forgetting and resetting passwords."
            />
            <Feature
              enumerator="03"
              heading="No Copy and Paste needed"
              text="You can simply click on the link in your email and if you have Conduct already installed on your machine,
                    it will launch Conduct and automatically add the library."
            />
            <Feature
              enumerator="04"
              heading="No Babysitting Downloads"
              text="Now with Conduct you can queue your downloads and leave them to download and install while you sleep.
                    Conduct fixes corruption as it goes so you don't need to worry about retrying downloads"
            />
            <Feature
              enumerator="05"
              heading="Run out of drive space?"
              text="Have you ever been half-way through downloading a large library and realised you've run out of space on your drive?
                    Conduct now has a 'move target' feature that will move all partially downloaded files to the new location"
            />
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
