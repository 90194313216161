import React, { useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Slide,
  Grow,
  Fade,
  Button
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import Logo from 'src/components/Logo';

interface HeroProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 200,
    paddingBottom: 200,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '70%',
      height: 'auto',
      transform: 'translateX(40px) rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

const Hero: FC<HeroProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const [text1In, setText1In] = useState(false);
  const [text2In, setText2In] = useState(false);
  const [text3In, setText3In] = useState(false);

  setTimeout(() => setText1In(true), 1000);
  setTimeout(() => setText2In(true), 1500);
  setTimeout(() => setText3In(true), 2000);

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Logo color="primary" />
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Fade in={true} timeout={1000}>
                <Box>
                  <Typography
                    variant="h2"
                    color="textPrimary"
                  >
                    WORLD-LEADER IN SAMPLE LIBRARY DOWNLOADING SINCE 2010
                  </Typography>
                  <Box mt={3}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                    >
                      Now presents...
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <Grid container>
                      <Grid item>
                        <img src="/static/conduct.svg" alt="Conduct logo" />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h4"
                          color="textPrimary"
                        >
                          The industry-leading download manager
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Fade>
              <Box mt={3}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grow in={text1In} timeout={1000}>
                    <Grid item>
                      <Typography
                        variant="h1"
                        color="secondary"
                      >
                        30+
                      </Typography>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                      >
                        Vendors
                      </Typography>
                    </Grid>
                  </Grow>
                  <Grow in={text2In} timeout={1000}>
                    <Grid item>
                      <Typography
                        variant="h1"
                        color="secondary"
                      >
                        9K+
                      </Typography>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                      >
                        Products
                      </Typography>
                    </Grid>
                  </Grow>
                  <Grow in={text3In} timeout={1000}>
                    <Grid item>
                      <Typography
                        variant="h1"
                        color="secondary"
                      >
                        1.2M+
                      </Typography>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                      >
                        Libraries Delivered
                      </Typography>
                    </Grid>
                  </Grow>
                </Grid>
                <Grid item>
                  <Box mb={2}/>
                  <Button
                    color="secondary"
                    component={RouterLink}
                    to="/download-app"
                    variant="contained"
                    fullWidth
                  >
                    Download Conduct
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <div className={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                />
              </div>
              <Slide direction="left" in={true} timeout={1000} mountOnEnter unmountOnExit>
                <div className={classes.image}>
                  <img
                    alt="Presentation"
                    src="/static/home/conduct-screenshot.png"
                  />
                </div>
              </Slide>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
