import React from 'react';
import {
  Avatar,
  Box,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

interface Props {
  enumerator: string;
  heading: string;
  text: string;
}
const Feature = ({ enumerator, heading, text }: Props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4}>
      <Box display="flex">
        <Avatar className={classes.avatar}>{enumerator}</Avatar>
        <Box ml={2}>
          <Typography variant="h4" gutterBottom color="textPrimary">
            {heading}
          </Typography>
          <Typography variant="body1" color="textPrimary" gutterBottom>
            {text}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
};
export default Feature;
