import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = ({ color, ...props}) => {
  const link = color === "primary" ? "/static/logo_blue.svg" : "/static/logo.svg";
  return (
    <img
      alt="Logo"
      src={link}
      {...props}
    />
  );
}

export default Logo;
