import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useFirebaseAuth';

interface AuthGuardProps {
  children?: ReactNode;
  redirectTo: string;
}

const AuthGuard: FC<AuthGuardProps> = ({ children, redirectTo }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to={redirectTo} />;
  }

  return <>{children}</>
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
