import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Grid,
  // Button,
  // Divider,
  Toolbar,
  Hidden,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import { APP_VERSION } from 'src/constants';
import Logo from 'src/components/Logo';
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import LockIcon from "@material-ui/icons/LockOpen";
import LibraryIcon from '@material-ui/icons/LibraryBooksOutlined';
import MailIcon from "@material-ui/icons/MailOutline";

interface TopBarProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    minHeight: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item md="auto">
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          </Grid>
          <Grid container item md={6} spacing={3} alignItems="center">
            <Grid item md="auto">
              <Link
                className={classes.link}
                color="textSecondary"
                component={RouterLink}
                to="/download-app"
                underline="none"
                variant="body2"
              >
                <DownloadIcon/>
                <Hidden mdDown>
                  <Typography variant="h5" display="inline">
                    {' '}Get Downloader
                  </Typography>
                </Hidden>
              </Link>
            </Grid>
            <Grid item md="auto">
              <Link
                className={classes.link}
                color="textSecondary"
                component={RouterLink}
                to="/register-gencode"
                underline="none"
                variant="body2"
              >
                <LockIcon/>
                <Hidden mdDown>
                  <Typography variant="h5" display="inline">
                    {' '}Register Product
                  </Typography>
                </Hidden>
              </Link>
            </Grid>
            <Grid item md="auto">
              <Link
                className={classes.link}
                color="textSecondary"
                component={RouterLink}
                to="/my-libraries"
                underline="none"
                variant="body2"
                >
                <LibraryIcon/>
                <Hidden mdDown>
                  <Typography variant="h5" display="inline">
                   {' '}My Libraries
                  </Typography>
                </Hidden>
              </Link>
            </Grid>
            <Grid item md="auto">
              {/* <Link
                className={classes.link}
                color="textSecondary"
                component={RouterLink}
                to="/projects"
                underline="none"
                variant="body2"
              >
                Projects
              </Link> */}
              <Link
                className={classes.link}
                color="textSecondary"
                component={RouterLink}
                to="/contact-us"
                underline="none"
                variant="body2"
              >
                <MailIcon/>
                <Hidden mdDown>
                  <Typography variant="h5" display="inline">
                    {' '}Contact Us
                  </Typography>
                </Hidden>
              </Link>
            </Grid>
              {/* <Divider className={classes.divider} /> */}
              {/* <Button
                color="secondary"
                component={RouterLink}
                to="/app"
                variant="contained"
              >
                Dashboard
              </Button> */}
          </Grid>
          <Grid item md="auto">
            <Hidden mdDown>
              <Typography
                variant="caption"
                color="textSecondary"
              >
                Version
                {' '}
                {APP_VERSION}
              </Typography>
            </Hidden>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
